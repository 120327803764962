// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-page-js": () => import("./../src/pages/about_page.js" /* webpackChunkName: "component---src-pages-about-page-js" */),
  "component---src-pages-bf-class-js": () => import("./../src/pages/bfClass.js" /* webpackChunkName: "component---src-pages-bf-class-js" */),
  "component---src-pages-bf-classes-js": () => import("./../src/pages/bfClasses.js" /* webpackChunkName: "component---src-pages-bf-classes-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programs-js": () => import("./../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-teachers-js": () => import("./../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-timetable-js": () => import("./../src/pages/timetable.js" /* webpackChunkName: "component---src-pages-timetable-js" */)
}

